import { defineStore } from "pinia"
import {$fetch} from 'ofetch'

export const useFileStore = defineStore("useFileStore", () => {
 const file =  useState<IDocFile | undefined>("pinia:file", ()=> undefined)
 const tKey = useState<string>("pinia:tenantKey", ()=> "")
 const activeFileKey = useState<string>("pinia:activeFileKey", ()=> "")
const error = useState<string>("pinia:error", ()=> "")
const pending = useState<boolean>("pinia:pending", ()=> false)

// const myHeaders = {
//   uid: user.value?.muid as string ?? "no-uid",
//   tenantKey: user.value?.companyId??'no-tenantKey',
//   departmentRef: user.value?.departmentRef ?? "no-departmentRef",
//   locationRef: user.value?.locationRef ?? "no-locationRef",
//   locationName: user.value?.locationName ?? "no-locationName",
//   companyRef: user.value?.companyRef ?? "no-companyRef",
//   departmentName: user.value?.departmentName ?? "no-departmentName",
//   companyName: user.value?.companyName ?? "no-companyName",
//   email: user.value?.email ?? "no-email",

// }

const init = async (tenantId: string)=>{
  tKey.value = tenantId
 }
  const getFile = async (tenantKey:string,fileKey: string) => {
    tKey.value = tenantKey
    activeFileKey.value = fileKey

    const result =  await $fetch<IDocFile>('/api/dbbs/document', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      },
      query: {
          docid: fileKey,
          tenantKey: tenantKey
      }
   })
    file.value = result
    return file
  }
  const refresh = async ()=>{
    await getFile(tKey.value, activeFileKey.value)
  }
  const onChangeFile = (id: string)=>{
    activeFileKey.value = id
    refresh()
  }

  const setStatus = async (tenantKey:string,  fileKey:string, status: string)=>{
    const payload = {
        tenantKey: tenantKey,
        status: status,            
        fileKey: fileKey
    }
    await $fetch(`/api/docs/setStatus`,{
        method: "POST",
        //@ts-ignore
        
        body: JSON.stringify(payload)
    })
    file.value!.status = status
    file.value!.statusUpdated = new Date()
}
const setStage = async (tenantKey:string, fileKey:string, stage: "open" | "processing" | "query" | "reviewing" | "done")=>{
         
  const payload = {
      tenantKey: tenantKey,
      stage: stage,                       
      fileKey: fileKey
  }
  try{
    await $fetch(`/api/docs/setStage`,{
        method: "POST",
        //@ts-ignore
        
        body: JSON.stringify(payload)
    })
    if(!file.value) return
    file.value!.stage = stage
    file.value.status = "open"
    file.value.stageUpdated = new Date()

  }catch(err:any){
    console.error(err)
    error.value = err.message as string
   }
}
const assignmentMetaToFile = async (dboKey: string, dbsKey:string, fileKey:string)=>{
  const payload = {
      tenantKey: tKey.value,
      dboKey: dboKey,
      dbsKey: dbsKey,
      fileKey: fileKey
  }
   const meta = await $fetch(`/api/docs/assignDbbsTeam`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value.stage = 'open'
  file.value.status = 'assigned'
  file.value.stageUpdated = new Date()
  file.value.statusUpdated = new Date()
  file.value.updated = new Date()
  file.value.meta = {...meta}
  return meta
}
const validationFieldUpdate = async (tenantKey:string, fileKey:string, value: IValidateCheck)=>{
  const payload = {
      tenantKey: tenantKey,
      fileKey: fileKey,            
      fieldValue: value            
  }
   const results = await $fetch(`/api/docs/validation`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value = {...file.value, ...results}
  return file
}
const startQuery = async (tenantKey:string,  fileKey:string, reason: string)=>{
  const payload = {
      tenantKey: tenantKey,
      reason: reason,            
      fileKey: fileKey
  }
  const resutls =  await $fetch(`/api/docs/query/start`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value =  {...file.value, ...resutls}
  return file
}

const updateDocFields = async (tenantKey:string, fileKey:string, updateDoc: any)=>{
  const payload = {
      tenantKey: tenantKey,
      fileKey: fileKey,
      data: updateDoc           
  }
  console.log("Update doc: payload",payload)
  const result =  await $fetch(`/api/docs/update/doctype`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value =  {...file.value, ...result}
  return file
}

const signatureCheckUpdate = async (tenantKey:string, fileKey:string, value: IValidateCheck)=>{
  const payload = {
      tenantKey: tenantKey,
      fileKey: fileKey,            
      fieldValue: value            
  }
  console.log("validationFieldUpdate: payload",payload)
  const result = await $fetch(`/api/docs/verification`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value =  {...file.value, ...result}
  return file
}

const signOff = async(tenantKey:string, fileKey:string, postedDocumentNo:string,
  owner:string, dbbsRemarks:string )=>{
  const body = {
      tenantKey: tenantKey ,
      fileKey: fileKey,
      postedDocumentNo: postedDocumentNo,
      owner: owner,
      dbbsRemarks: dbbsRemarks,
  }
  const result = await $fetch(`/api/docs/signoff`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(body)
  })
  if(!file.value) return
  file.value =  {...file.value, ...result}
  return file
}

const archive = async(tenantKey:string, fileKey:string, owner:string,  )=>{
  const body = {
      tenantKey: tenantKey ,
      fileKey: fileKey,           
      owner: owner,
  }
  const result = await $fetch(`/api/docs/archive`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(body)
  })
  if(!file.value) return
  file.value =  {...file.value, ...result}
  return file
}

const closeQuery = async (tenantKey:string, fileKey:string, reason: string)=>{
  const payload = {
      tenantKey: tenantKey,
      reason: reason,            
      fileKey: fileKey
  }
  const result = await $fetch(`/api/docs/query/close`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value =  {...file.value, ...result}
  return file
}

const updateDocLog = async (tenantKey:string, fileKey:string, doclog: any)=>{
  const payload = {
      tenantKey: tenantKey,
      fileKey: fileKey,
      data: doclog           
  }
  console.log("Update doc: Doclog",payload)
  const resutlt =  await $fetch(`/api/docs/update/doclog`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value =  {...file.value, ...resutlt}
  return file
}
const markPosted = async (tenantKey:string, fileKey:string)=>{
  const payload = {
      tenantKey: tenantKey,
      fileKey: fileKey,                       
  }
  console.log("Update doc: Doclog",payload)
  const result = await $fetch(`/api/docs/update/markposted`,{
      method: "POST",
      //@ts-ignore
      
      body: JSON.stringify(payload)
  })
  if(!file.value) return
  file.value =  {...file.value, ...result}
  return file
}
const setActiveView = async(view: string)=>{
  const payload = {
      view: view,
      fileKey: activeFileKey.value,                       
      tenantKey: tKey.value
  }
  if(!file.value) return 
  file.value.activeView = view
  
  const result = await $fetch(`/api/docs/setview`,{
    method: "POST",
    //@ts-ignore
    
    body: JSON.stringify(payload)
})

return result
}

  return {
    init,
    file, getFile,
     error, pending, 
    refresh, onChangeFile,
    setStatus, setStage, assignmentMetaToFile, 
    validationFieldUpdate,startQuery,updateDocFields, 
    signatureCheckUpdate,signOff, archive,
     closeQuery, updateDocLog, markPosted,
     setActiveView
  }

},{persist: {storage: persistedState.localStorage}})
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFileStore, import.meta.hot))

}